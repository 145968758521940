<template>
  <div class="pt-16">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full h-75vh md:h-50vh lg:h-75vh py-12 px-4 md:px-0 flex items-center"
      >
        <div class="w-full bg-softGrey80">
          <p
            class="md:w-768 lg:w-1024 m-auto p-4 text-xl text-pulpBlue text-center font-semibold"
          >
            Built by educators for educators, neo's English program enables your
            learners to achieve their English fluency goals, whether your
            institution requires fully online instruction or a seamless blend of
            classroom and online learning.​
          </p>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Get Students Assessed and On Their Journey to English Fluency​
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              neo’s initial Assessment Test for Placement evaluates your
              students' precise English level. This starts them on their English
              journey. Learners then log in to neoStudy and select the Study Now
              button – that's it! Thanks to neo’s integrated artificial
              intelligence (AI) and dynamic student path, neo will continually
              monitor and adjust to each student’s language needs. ​
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Ask about neoAssess for screening University entry-level
              international students
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              neoAssess evaluates your learners’ English skills with precision
              both in their initial assessment and at regular intervals during
              their studies. neoAssess ensures learners meet your
              English graduation requirements and are ready to take on the
              challenges of today's global markets. ​
              <a
                class="text-secondary"
                href="mailto:info@nexgenenglishonline.co"
                >Learn more</a
              >
            </p>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Statement of Results​
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              You can generate and download a Statement of Results (SOR) for
              every student that takes the Assessment Test. The SOR details how they
              score in each of the different language areas and help teachers
              focus on areas where students need further support on their
              English journey. Statements of Results can be printed for students
              or parents, they can be co-branded with your school’s logo, and
              they can be verified by other schools and employers through our
              certificate UDID
              <a
                class="text-secondary"
                href="https://neo-verify.com/ "
                target="_blank"
                >verification system</a
              >.​
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-40 lg:h-52"
                src="@/assets/images/icons/A.-NEOSTUDY.png"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Fully Accessible Courses with neoStudy Online and/or neoStudy
                  App​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  Students access neo courseware using Google Chrome on
                  Chromebooks, Windows or Mac computers with neoStudy Online.
                  Students can extend their study sessions to their tablets or
                  smartphones using the same credentials to log in to the
                  neoStudy App. Regardless of when and where they log in, their
                  study data synchronizes across all their devices to our secure
                  cloud servers. ​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full text-textBlack text-left">
            <h3 class="text-2xl text-primary font-semibold">
              neo Curricula to choose from:​ ​
            </h3>
            <ul class="flex flex-wrap md:flex-nowrap text-textBlack">
              <li class="md:w-4/12 bg-softGrey py-4 px-6 mt-6">
                <h5 class="font-semibold text-center text-pulpBlue text-lg">
                  neoJr+ ​<br />
                  Ages 4-9​
                </h5>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  neoJr+ focuses on the basic framework of English. It offers
                  young learners a fun and eﬀective way to learn English. With
                  neoJr+, emergent bilinguals develop the language base that is
                  a prerequisite for eﬀective literacy development and the
                  neoPrep+ course. ​
                </p>
              </li>
              <li class="md:w-4/12 bg-softGrey py-4 px-6 md:mx-2 mt-6">
                <h5 class="font-semibold text-center text-pulpBlue text-lg">
                  neoPrep+ ​<br />
                  Ages 10-18​
                </h5>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  neoPrep+ is designed for teens and young adults who need to
                  succeed in academic learning environments. It focuses on
                  school subjects and social interactions in English.
                  Additionally, a variety of highly interactive listening– and
                  reading-based activities that help learners improve their
                  fluency skills are included.
                </p>
              </li>
              <li class="md:w-4/12 bg-softGrey py-4 px-6 mt-6">
                <h5 class="font-semibold text-center text-pulpBlue text-lg">
                  neo+​ <br />
                  Ages 16+ ​
                </h5>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  neo+ helps young adult and adult learners develop the
                  communication skills necessary to succeed in today’s global
                  economy—the perfect English-language solution for college
                  preparatory, higher education, and professional programs.​
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48 py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-40 lg:h-52"
                src="@/assets/images/icons/B.-WORKBOOKS.png"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Our comprehensive solution provides students, teachers, and
                  administrators with the tools they need, when they need them.​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  While students learn independently with neoStudy, neo works
                  behind the scenes to serve and support teachers and
                  administrators. ​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-between"
        >
          <div class="w-full text-textBlack text-left">
            <ul class="flex flex-wrap md:flex-nowrap text-textBlack">
              <li
                class="w-full md:w-1/5 text-center bg-softGrey py-4 px-6 flex items-center justify-center"
              >
                <div>Points-based goal setting platform​</div>
              </li>
              <li
                class="w-full md:w-1/5 text-center bg-softGrey py-4 px-6 md:ml-1 mt-1 md:mt-0 flex items-center justify-center"
              >
                <div>Progress Reports​</div>
              </li>
              <li
                class="w-full md:w-1/5 text-center bg-softGrey py-4 px-6 md:mx-1 my-1 md:my-0 flex items-center justify-center"
              >
                <div>On demand AI-curated class activities​</div>
              </li>
              <li
                class="w-full md:w-1/5 text-center bg-softGrey py-4 px-6 md:mr-1 mb-1 md:mb-0 flex items-center justify-center"
              >
                <div>Student <br />​ Workbooks​​</div>
              </li>
              <li
                class="w-full md:w-1/5 text-center bg-softGrey py-4 px-6 flex items-center justify-center"
              >
                <div>CEFR-Aligned <br />​ certificates​</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-white py-16">
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-72"
                src="@/assets/images/icons/C.-Youngboywithcert.jpg"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  CEFR-aligned Certification program​
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  Learners advance from one CEFR level to the next by completing
                  all the lessons and tests for each of the levels in their
                  course. When all requirements are met, they earn
                  internationally accepted English-language certificates aligned
                  to
                  <i
                    >the Common European Framework of Reference for Languages</i
                  >
                  (CEFR) and verified by DynEd.​
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto px-4 md:px-0 flex flex-col-reverse md:flex-row justify-between"
        >
          <div class="mx-auto text-textBlack text-left">
            <ul
              class="flex flex-wrap md:flex-nowrap justify-center text-textBlack"
            >
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 flex items-center justify-center"
              >
                <div>
                  Co-branded​ <br />

                  Certificates available​​
                </div>
              </li>
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 md:mx-2 my-1 md:my-0 flex items-center justify-center"
              >
                <div>
                  Certificates can be downloaded from the neoDashboard​​
                </div>
              </li>
              <li
                class="w-full md:w-3/12 text-center bg-softGrey py-4 px-6 flex items-center justify-center"
              >
                <div>
                  Certificate authenticity can be verified
                  <router-link
                    class="text-secondary"
                    to="/studentcertificateverification"
                    >online</router-link
                  >
                  ​​
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <ul class="mb-20 flex justify-center">
      <li
        class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
      >
        Download more info
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/8.NEW-admin-section-photo.jpg"),
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/8-admin-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/8.NEW-admin-section-photo.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>